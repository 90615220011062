import axios from 'axios';
// import { generateJWT } from '@/utils';
const apiUrl = import.meta.env.VITE_API_URL;
const vtoken = import.meta.env.VITE_TOKEN;
const vuser = import.meta.env.VITE_USER;

const redirectTo = (url) => {
	window.location.href = url;
};

function httpService() {
	const service = axios.create();

	service.interceptors.request.use(
		function (config) {
			const token = window.localStorage.getItem(vtoken);
			// const user = JSON.parse(window.localStorage.getItem(vuser));
			const lang = window.localStorage.getItem('lang') || 'en';
			// Do something before request is sent
			return {
				...config,
				baseURL: apiUrl,
				headers: token
					? {
							Authorization: 'Bearer ' + token,
							// 'X-W-Auth': generateJWT(user.email, token),
							'X-Locale': lang,
					  }
					: {
							// 'Access-Control-Allow-Origin': '*',
							'X-Locale': lang,
					  },
			};
		},
		function (error) {
			// Do something with request error
			return Promise.reject(error);
		}
	);

	service.interceptors.response.use(handleSuccess, handleError);

	function handleSuccess(response) {
		return response;
	}

	function handleError(error) {
		const status = error.response.status;
		switch (status) {
			case 400:
				// Not found
				console.log('Bad Request');
				// redirectTo('/400');
				break;
			case 403:
				// Unauthorized
				// Token expired
				window.localStorage.removeItem(vtoken);
				window.localStorage.removeItem(vuser);
				redirectTo(`/login`);
				console.log('Token expired');
				break;
			case 404:
				// Not found
				console.log('Not found');
				// redirectTo('/404');
				break;
			default:
				// Internal server error
				console.log('Internal server error');
				// redirectTo('/500');
				break;
		}

		// FIXME: custom error 500
		const response =
			status === 500
				? {
						data: {
							errors: {
								code: 'Internal server error',
							},
						},
				  }
				: error.response;


		return { status, ...response };
	}

	return {
		get: (...args) => service.get(...args),
		post: (...args) => service.post(...args),
		put: (...args) => service.put(...args),
		patch: (...args) => service.patch(...args),
		delete: (...args) => service.delete(...args),
	};
}

export default httpService();
