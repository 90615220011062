import dayjs from 'dayjs';
// import 'dayjs/locale/fr'
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
// dayjs.locale('fr')
export default dayjs;
