import i18n from '@/locale';

export default {
	emptyRules: (v) => !!v || i18n.t('error.requiredField'),
	emptyArrayRules: (v) => (!!v && v.length !== 0) || i18n.t('error.requiredField'),
	minLengthRules: (v) => !v || v.length >= 6 || i18n.t('error.minLength', { number: 6 }),
	maxLength25Rules: (v) =>
		!v ||
		(v && v.replace(/\s+/g, '').length <= 25) ||
		i18n.t('error.maxLength', { number: 25 }),
	maxLength120Rules: (v) =>
		!v ||
		(v && v.replace(/\s+/g, '').length <= 120) ||
		i18n.t('error.maxLength', { number: 120 }),
	maxLength255Rules: (v) =>
		!v ||
		(v && v.replace(/\s+/g, '').length <= 255) ||
		i18n.t('error.maxLength', { number: 255 }),
	phoneRules: (v) =>
		!v || (v.length === 12 && /^09|03|07|08|05/.test(v)) || i18n.t('error.invalidPhone'),
	emailRules: (v) => !v || /.+@.+\..+/.test(v) || i18n.t('error.invalidEmail'),
	passwordRules: (v) =>
		/^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{8,36}$/.test(
			v
		) || i18n.t('error.passwordRequired'),
	urlRules: (v) =>
		/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
			v
		) || i18n.t('error.urlRequired'),
	expiredDateRules: (v) =>
		/^(0?0[1-9]|1[0-2])\/[1-9]{1}[0-9]{1}$/.test(v) || i18n.t('error.secondInvalidDate'),
};
