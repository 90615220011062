<template>
	<div
		v-if="show"
		class="fixed d-flex align-center justify-center bg-light"
		style="z-index: 50; left: 0; right: 0; top: 0; bottom: 0; backdrop-filter: blur(4px)">
		<v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
	</div>
</template>
<script>
export default {
	props: {
		show: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
