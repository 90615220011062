<template>
	<v-dialog v-model="dialog" :max-width="maxWidth" v-bind="$attrs" :persistent="persistent">
		<div
			class="relative bg-white rounded d-flex flex-column justify-center"
			:class="[className]">
			<div class="absolute" style="right: -0.5rem; top: -0.5rem" v-if="closeBtn">
				<v-btn density="comfortable" icon color="red lighten-2" @click="close">
					<v-icon>$mdiClose</v-icon>
				</v-btn>
			</div>

			<div class="text-center" v-if="logo">
				<v-img
					alt="logo"
					:src="assetsUrl('media/logo.webp')"
					class="mx-auto mb-4"
					style="margin-top: -20px"
					:width="80"
					:height="80" />
			</div>

			<div class="px-4 d-flex justify-center">
				<div>
					<div v-for="(val, key) in txts" :key="key" class="mb-2 text-center">
						{{ val }}
					</div>
				</div>
			</div>

			<div class="text-center mt-4">
				<v-btn
					v-if="confirmBtn"
					class="text-white"
					color="primary"
					elevation="0"
					@click="close(1)"
					style="min-width: 120px">
					{{ confirmTxt || 'Ok' }}
				</v-btn>
				<v-btn
					v-if="cancelBtn"
					class="text-white ml-4"
					color="primary"
					elevation="0"
					variant="outlined"
					@click="close(0)"
					style="min-width: 120px">
					{{ cancelTxt || t('common.cancel') }}
				</v-btn>
			</div>
		</div>
	</v-dialog>
</template>
<script>
export default {
	props: {
		modelValue: {},
		className: {
			type: String,
			default: 'pa-5',
		},
		maxWidth: {
			type: Number,
			default: 500,
		},
		persistent: {
			type: Boolean,
			default: false,
		},
		closeBtn: {
			type: Boolean,
			default: false,
		},
		confirmBtn: {
			type: Boolean,
			default: true,
		},
		confirmTxt: {
			type: String,
			default: '',
		},
		cancelBtn: {
			type: Boolean,
			default: false,
		},
		cancelTxt: {
			type: String,
			default: '',
		},
		logo: {
			type: Boolean,
			default: true,
		},
		txts: {
			type: Object,
			default: () => {},
		},
	},
	computed: {
		dialog: {
			get() {
				return this.modelValue;
			},
			set(val) {
				this.$emit('update:modelValue', val);
			},
		},
	},
	methods: {
		open() {
			this.dialog = true;
		},
		close(val) {
			this.dialog = false;
			this.$emit('onClose', val);
		},
	},
};
</script>
<style lang="scss" scoped>
.theme--light {
	.v-dialog {
		.v-card {
			background-color: #f8f8f8 !important;
		}
	}
}
.absolute {
	position: absolute;
}
.relative {
	position: relative;
}
.right-0 {
	right: 0;
}
</style>
