const vtoken = import.meta.env.VITE_TOKEN;
// Route name do not need permissions
// const whiteList = []

export async function middlewareLayout(to, from, next) {
	try {
		const layout = to.meta.layout ?? 'Default';
		const theme = await import(`@/layouts/${layout}.vue`);
		to.meta.theme = theme.default;
		const token = localStorage.getItem(vtoken);

		if (token) {
			const nameRoutes = ['Login', 'Register'];
			if (nameRoutes.includes(to.name)) next({ path: from.path });
			else next();
		} else {
			if (to.name == 'Detail') { // Use for redirecting to previous page after submit detail
				to.redirectedFrom = from.path;
			}
			// FIXME : case block page
			// if (whiteList.includes(to.name)) next();
			// else next({ path: `/login` });

			// no page
			next();
		}
	} catch (e) {
		console.log(e);
		const theme = await import(`@/layouts/Default.vue`);
		route.meta.theme = theme.default;
	}
}
