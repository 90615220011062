<template>
	<span class="d-inline-block">
		<inline-svg
			v-if="icon.search(/mdi|fa-/) === -1"
			:src="icon"
			:width="width"
			:height="height"
			:alt="alt" />
		<v-icon v-else :size="width">{{ icon }}</v-icon>
	</span>
</template>
<script>
import InlineSvg from 'vue-inline-svg';
export default {
	components: { InlineSvg },
	props: {
		icon: {
			type: String,
			default: ' ',
		},
		width: {
			type: Number,
			default: 20,
		},
		height: {
			type: Number,
			default: 20,
		},
		alt: {
			type: String,
			default: 'label',
		},
	},
};
</script>
