import { createRouter, createWebHistory } from 'vue-router';
import { middlewareLayout } from './middleware';

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: '/',
			name: 'home',
			component: () => import('@/views/Home.vue'),
			meta: {
				layout: 'MenuWithFooter',
			},
		},
		{
			path: '/login',
			name: 'Login',
			component: () => import('@/views/Login.vue'),
			meta: {
				layout: 'Default',
			},
		},
		{
			path: '/register',
			name: 'Register',
			component: () => import('@/views/Register.vue'),
			meta: {
				layout: 'Default',
			},
		},
		{
			path: '/forgot',
			name: 'Forgot',
			component: () => import('@/views/Forgot.vue'),
			meta: {
				layout: 'Default',
			},
		},
		{
			path: '/profile',
			name: 'Profile',
			component: () => import('@/views/Profile.vue'),
			meta: {
				layout: 'Default',
			},
		},
		{
			path: '/history',
			name: 'History',
			component: () => import('@/views/History.vue'),
			meta: {
				layout: 'MenuNoHeader',
			},
		},
		{
			path: '/user',
			name: 'User',
			component: () => import('@/views/User.vue'),
			meta: {
				layout: 'MenuNoHeader',
			},
		},
		{
			path: '/detail',
			name: 'Detail',
			component: () => import('@/views/Detail.vue'),
			meta: {
				layout: 'Default',
			},
		},
		{
			path: '/cart',
			name: 'Cart',
			component: () => import('@/views/Cart.vue'),
			meta: {
				layout: 'Default',
			},
		},
		{
			path: '/payment-callback',
			name: 'PaymentCallback',
			component: () => import('@/views/PaymentCallback.vue'),
			meta: {
				layout: 'Empty',
			},
		},
		{
			path: '/cooking/:id',
			name: 'Cooking',
			component: () => import('@/views/Cooking.vue'),
			meta: {
				layout: 'MenuNoHeader',
			},
		},
		{
			path: '/policy/:id',
			name: 'Policy',
			component: () => import('@/views/Policy.vue'),
			meta: {
				layout: 'Empty',
			},
		},
		{
			path: '/:pathMatch(.*)*',
			name: 'NotFound',
			component: () => import('@/views/NotFound.vue'),
			meta: {
				layout: 'Empty',
			},
		},
	],
});

router.beforeEach(middlewareLayout);
router.afterEach(() => {
	setTimeout(() => {
		if (window) {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	}, 200);
});

export default router;
