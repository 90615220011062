export const light = {
	dark: false,
	colors: {
		background: '#FFFFFF',
		surface: '#D78C7F',
		primary: '#D98073',
		secondary: '#EED1BE',
		error: '#BC1111',
		info: '#4496F3',
		success: '#16DB93',
		warning: '#CB8C00',
	},
};

export const dark = {
	dark: true,
	colors: {
		background: '#4A4A4A',
		surface: '#D78C7F',
		primary: '#D98073',
		secondary: '#EED1BE',
		// 'secondary-darken-1': '#03adf4',
		error: '#BC1111',
		info: '#4496F3',
		success: '#16DB93',
		warning: '#CB8C00',
	},
};
