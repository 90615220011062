import { defineStore } from 'pinia';
import { api, common } from '@/services';
import i18n from '@/locale';
import Storage from '@/utils/storage';
import router from '@/router';
const vtoken = import.meta.env.VITE_TOKEN;
const vuser = import.meta.env.VITE_USER;

export const useAuthStore = defineStore({
	id: 'AuthStore',
	state: () => ({
		lang: Storage.get('lang', 'en'),
		token: Storage.get(vtoken, ''),
		user: Storage.get(vuser, {}),
	}),
	actions: {
		setLang(data) {
			this.lang = data;
			Storage.set('lang', data); // local storage
			i18n.locale = data;
		},
		setUser(data) {
			// update pinia state token
			this.token = data.token;
			Storage.set(vtoken, this.token); // local storage
			delete data.token;

			// update pinia state user
			this.user = data;
			Storage.set(vuser, this.user); // local storage
		},
		// async reloadUser() {
		// 	if (this.token) {
		// 		const res = await api.get(`${common.login}/${this.user.id}`);
		// 		const data = res.data ?? {};
		// 		if (!data.error) {
		// 			this.setUser(data);
		// 		}
		// 	}
		// },
		async login(body) {
			try {
				const res = await api.post(common.login, body);
				const data = res.data ?? {};
				if (data.error) {
					this.error = data.data;
					return;
				}

				// update pinia state mode
				this.setUser(data);

				router.push(redirectTo);
			} catch (error) {
				// alert error
				console.log(error);
			}
		},
		logout(path = '/') {
			Storage.removeAll();
			this.token = null;
			this.user = {};
			setTimeout(() => {
				window.location.href = path;
			}, 120);
		},
	},
});
