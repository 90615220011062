<template>
	<transition name="fade" appear>
		<component :is="$route.meta.theme">
			<slot />
		</component>
	</transition>
</template>

<script>
export default {
	name: 'AppLayout',
};
</script>
