import { createApp } from 'vue';
import { createPinia } from 'pinia';

import './assets/main.css';
import App from './App.vue';
import router from '@/router';

// Vuetify
import vuetify from './plugins/vuetify';
import mixin from './plugins/mixin';

// Scrollbar
import VueLazyload from 'vue-lazyload-ssr'
import { PerfectScrollbarPlugin } from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/style.css';

const app = createApp(App);
app.use(createPinia());
app.use(router);
app.use(vuetify);
app.use(VueLazyload, {
  lazyComponent: true,
  throttleWait: 50,
});
app.use(PerfectScrollbarPlugin);
app.mixin(mixin);
app.mount('#app'); // Mount app

// Import component
import IconInline from '@/components/IconInline.vue';
app.component('IconInline', IconInline);
import Pic from '@/components/Pic.vue';
app.component('Pic', Pic);
import PopupMessage from '@/components/Popup/Message.vue';
app.component('PopupMessage', PopupMessage);
import Waiting from '@/components/Waiting.vue';
app.component('Waiting', Waiting);

