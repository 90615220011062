const BASE_URL = import.meta.env.VITE_BASE_URL;
import i18n from '@/locale';
import rules from '@/utils/rules';
export default {
	computed: {
		emptyRules: () => [rules.emptyRules],
		emptyArrayRules: () => [rules.emptyArrayRules],
		minLengthRules: () => [rules.minLengthRules],
		maxLength25Rules: () => [rules.maxLength25Rules],
		maxLength120Rules: () => [rules.maxLength120Rules],
		maxLength255Rules: () => [rules.maxLength255Rules],
		phoneRules: () => [rules.phoneRules],
		emailRules: () => [rules.emailRules],
		passwordRules: () => [rules.passwordRules],
		urlRules: () => [rules.urlRules],
		expiredDateRules: () => [rules.expiredDateRules],
	},
	methods: {
		/**
		 * i18n
		 */
		t(str, opt = {}) {
			if (!str) return str;
			return i18n.t(str, opt);
		},
		setLocale(lang) {
			i18n.locale = lang || 'en';
		},
		/**
		 * end i18n
		 */
		parseCurrency: (val) => {
			return String(val || '').replace(/(\d)(?=(\d{3})+\b)/g, '$1.');
		},
		getNested(item, key) {
			try {
				return key.split('.').reduce((a, b) => a[b], item);
			} catch (err) {
				// FIXME TypeError: Cannot read properties of null (reading 'keyname')
				// console.log(err);
				return '';
			}
		},
		getName(list = [], obj) {
			const id = obj?.id || obj;
			const item = list.find((x) => x.id == id);
			return item?.name;
		},
		listDisc(items) {
			const arr = Array.isArray(items) ? items : [];
			let str = '';
			for (const el of arr) {
				str += `• ${this.truncateText(el.name, 42)}\n`;
			}
			return str;
		},
		expriedDate(end) {
			return expriedDate(end);
		},
		assetsUrl(relativePath) {
			return `${BASE_URL}${relativePath}`;
		},
		// apiUrl(relativePath) {
		// 	return `${HOST}${relativePath}`;
		// },
		saveFile(file, fileName) {
			const downloadLink = document.createElement('a');
			downloadLink.href = file;
			downloadLink.download = fileName;
			downloadLink.click();
		},
		truncateText(val, length = 40) {
			const more = val.length > length ? '...' : '';
			return val.substring(0, length) + more;
		},
		// parse errors from api
		parseErrors(code) {
			const err = code?.data?.errors || {};
			const data = {};
			for (const i in err) {
				const items = [];
				for (const el of err[i]) {
					items.push(i18n.t(el));
				}
				data[i] = items.join('\n');
			}
			return data;
		},
	},
};
