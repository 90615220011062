<template>
	<div
		:style="{
			'min-width': width + 'px',
			'max-width': maxWidth ? maxWidth : 'auto',
			'aspect-ratio': width / height,
		}">
		<v-img
			v-if="src"
			:src="src"
			:aspect-ratio="width / height"
			:cover="cover"
			:position="position"
			:alt="alt"
			class="mx-auto" />
		<div
			v-else
			class="vpic"
			:style="{
				'min-width': width + 'px',
				'aspect-ratio': width / height,
				'background-position': 'center',
				'background-image': preview ? `url(${preview})` : 'none',
				'background-size': previewSize,
			}" />
	</div>
</template>
<script>
export default {
	props: {
		maxWidth: {},
		width: {
			type: Number,
			default: 120,
		},
		height: {
			type: Number,
			default: 120,
		},
		src: {
			type: String,
			default: '',
		},
		cover: {
			type: Boolean,
			default: false,
		},
		position: {
			type: String,
			default: 'center',
		},
		previewSize: {
			type: String,
			default: '60px',
		},
		alt: {
			type: String,
			default: 'label',
		},
	},
	computed: {
		preview() {
			return this.assetsUrl('media/logo.webp');
		},
	},
};
</script>
<style>
.vpic {
	background-color: rgba(0, 0, 0, 0.1);
}
</style>
