import { default as common } from './common.json';
import { default as error } from './error.json';
import { default as date } from './date.json';
import { default as route } from './route.json';

/**
 * use $t('common.signin') or use lib i18n.t('common.signin')
 */
export default {
	common,
	error,
	date,
	route,
};
