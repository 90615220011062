import { I18n } from 'i18n-js';
import vuetify from '@/locale/vuetify';
import en from './en';
import vi from './vi';

const messages = {
	vi: {
		...vi,
		$vuetify: vuetify.vi,
	},
	en: {
		...en,
		$vuetify: vuetify.en,
	},
};

/**
 * Available locales
 */
export const locales = [
	{
		title: 'Tiếng việt',
		locale: 'vi',
		abbr: 'VI',
	},
	{
		title: 'English',
		locale: 'en',
		abbr: 'ENG',
	},
];

/**
 * I18n instance
 */
const i18n = new I18n({
	...messages,
});
i18n.enableFallback = true;
i18n.defaultLocale = 'vi';
i18n.locale = 'vi';

export default i18n;
